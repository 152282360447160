import React from 'react';
import '../graphql-fragments/localeFragments.ts';

import IndexPage from '../components/IndexPage';

const CitizenshipAeIndexPage = (): React.ReactElement => {
  return (
    <IndexPage
      country="ae"
      pageTheme="citizenship"
      googleTagManagerTrackingId="GTM-PXD7DHW"
    ></IndexPage>
  );
};

export default CitizenshipAeIndexPage;
